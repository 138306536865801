var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择规格项",
        visible: _vm.dialogVisible,
        "before-close": _vm.clearModel,
        "append-to-body": true,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        open: () => {
          if (_vm.tableData.length === 0) _vm.getList()
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px", "margin-right": "10px" },
                attrs: {
                  size: "small",
                  placeholder: "请输入规格项名称",
                  clearable: "",
                },
                on: { clear: _vm.getList },
                model: {
                  value: _vm.specName,
                  callback: function ($$v) {
                    _vm.specName = $$v
                  },
                  expression: "specName",
                },
              }),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.getList },
                },
                [_vm._v(" 搜索 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.tableData.length === 0
                ? _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(" 暂无数据 "),
                  ])
                : _vm._e(),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.onChangeRadio },
                  model: {
                    value: _vm.radioActive,
                    callback: function ($$v) {
                      _vm.radioActive = $$v
                    },
                    expression: "radioActive",
                  },
                },
                _vm._l(_vm.tableData, function (item, i) {
                  return _c(
                    "el-radio",
                    {
                      key: i,
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "margin-bottom": "10px",
                      },
                      attrs: {
                        label: item.id,
                        disabled:
                          item.status == 0 ||
                          (_vm.skuData.find((find) => find.id == item.id) &&
                            _vm.specData.id != item.id),
                      },
                    },
                    [_c("div", [_vm._v(_vm._s(item.name))])]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.clearModel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.tableData.find(
                  (item) => item.id === _vm.radioActive
                ),
                type: "primary",
                size: "small",
              },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }