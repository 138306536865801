var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "设置规格值",
        visible: _vm.dialogVisible,
        "before-close": _vm.clearModel,
        "append-to-body": true,
        width: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.openDialog,
        closed: function ($event) {
          _vm.specValue = ""
        },
      },
    },
    [
      _vm.specData.valueType == 2
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.checkList,
                    callback: function ($$v) {
                      _vm.checkList = $$v
                    },
                    expression: "checkList",
                  },
                },
                _vm._l(_vm.specList, function (item, i) {
                  return _c(
                    "el-checkbox",
                    {
                      key: i,
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { label: item.id },
                    },
                    [
                      _vm._v(" " + _vm._s(item.name) + " "),
                      item.belongType === 2
                        ? _c("i", {
                            staticClass: "el-icon-delete",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.deleteSkuVal(item)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }),
                1
              ),
              _c(
                "el-link",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showSelf = true
                    },
                  },
                },
                [_vm._v(" +添加自定义规格值 ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.specData.valueType == 1
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c("InputModel", {
                attrs: {
                  "spec-value": _vm.specValue,
                  "unit-date": _vm.specData.tradeUnitDto,
                },
                on: {
                  "update:specValue": function ($event) {
                    _vm.specValue = $event
                  },
                  "update:spec-value": function ($event) {
                    _vm.specValue = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.specData.valueType == 3
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _vm._v(" 请选择时间： "),
              _c("el-time-picker", {
                attrs: {
                  format: "HH:mm",
                  placeholder: "选择时间",
                  "value-format": "HH:mm",
                  size: "small",
                },
                model: {
                  value: _vm.specValue,
                  callback: function ($$v) {
                    _vm.specValue = $$v
                  },
                  expression: "specValue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.clearModel } },
            [_vm._v("取 消")]
          ),
          +_vm.specData.valueType === 2
            ? _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.specList || _vm.specList.length === 0,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("保 存")]
              )
            : _vm._e(),
          +_vm.specData.valueType === 1 || +_vm.specData.valueType === 3
            ? _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.specValue,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("保 存")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加自定义规格值",
            visible: _vm.showSelf,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelf = $event
            },
            closed: function ($event) {
              _vm.specValue = ""
            },
          },
        },
        [
          _c("InputModel", {
            attrs: {
              "spec-value": _vm.specValue,
              "unit-date": _vm.specData.tradeUnitDto,
            },
            on: {
              "update:specValue": function ($event) {
                _vm.specValue = $event
              },
              "update:spec-value": function ($event) {
                _vm.specValue = $event
              },
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.specValue,
                    type: "primary",
                    size: "mini",
                  },
                  on: { click: _vm.onAddSelf },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }