var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-input",
    {
      attrs: { placeholder: "请输入规格值", size: "small" },
      on: {
        input: function ($event) {
          return _vm.onInputUnit("title", _vm.unitDate)
        },
      },
      model: {
        value: _vm.title,
        callback: function ($$v) {
          _vm.title = $$v
        },
        expression: "title",
      },
    },
    [
      _vm.unitDate && _vm.unitDate.unitName
        ? _c("template", { slot: "append" }, [
            _vm._v(_vm._s(_vm.unitDate.unitName)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }