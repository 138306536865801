<template>
  <el-dialog
    title="选择规格项"
    :visible.sync="dialogVisible"
    :before-close="clearModel"
    :append-to-body="true"
    width="800px"
    @open="()=> {if(tableData.length === 0) getList()}"
  >
    <div v-loading="loading">
      <div style="margin-bottom: 10px;">
        <el-input
          v-model="specName"
          size="small"
          placeholder="请输入规格项名称"
          clearable
          style="width: 200px; margin-right: 10px;"
          @clear="getList"
        />
        <el-button
          size="small"
          type="primary"
          @click="getList"
        >
          搜索
        </el-button>
      </div>

      <div>
        <div
          v-if="tableData.length === 0"
          style="text-align: center;"
        >
          暂无数据
        </div>
        <el-radio-group
          v-model="radioActive"
          @change="onChangeRadio"
        >
          <el-radio
            v-for="(item,i) in tableData"
            :key="i"
            :label="item.id"
            :disabled="item.status == 0 || (skuData.find( find=> find.id == item.id ) && specData.id != item.id)"
            style="display: flex;align-items: center;margin-bottom: 10px;"
          >
            <div>{{ item.name }}</div>
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="clearModel"
      >取 消</el-button>
      <el-button
        :disabled="!tableData.find( item=> item.id === radioActive )"
        type="primary"
        size="small"
        @click="onSubmit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    specData: {
      type: Object,
      default: () => ({}),
    },
    skuData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableData: [],
      loading: false,
      specName: '',
      radioActive: '',
      query: {},
    };
  },
  computed: {},
  watch: {
    specData: {
      handler(val) {
        if (val) this.radioActive = val.id;
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.query = this.$route.query;
  },
  methods: {
    clearModel() {
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      this.$emit('update:specData', this.tableData.find((item) => item.id == this.radioActive));
      this.clearModel();
    },
    getList() {
      this.loading = true;
      this.$axios
        .post(this.$api.goods_spec.page, {
          specName: this.specName,
          backendCategoryId: this.query.backendCategoryId,
        })
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data.records;
          } else {
            this.tableData = [];
          }
          this.loading = false;
        });
    },
    onChangeRadio(e) {
      console.log(e);
      if (this.skuData.find((item) => item.id == e) && this.specData.id != e) {
        this.radioActive = null;
        this.$message.error('规格组不能重复');
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
