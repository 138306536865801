<template>
  <el-input 
    v-model="title" 
    placeholder="请输入规格值" 
    size="small" 
    @input="onInputUnit('title',unitDate)" 
    >
    <template v-if="unitDate && unitDate.unitName" slot="append">{{unitDate.unitName}}</template>
  </el-input>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    specValue: {
      type: String,
      default: ''
    },
    unitDate: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      title: ''
    }
  },
  computed: {},
  watch: {
    title: {
      handler (val) {
        this.$emit('update:specValue', val)
      },
      deep: true
    },
    specValue: {
      handler (val) {
        this.title = val
      },
      deep: true
    }
  },
  created () {},
  mounted () {
    
  },
  methods: {
    onInputUnit(model, unit) {
      if(!unit || unit.dataType === 0) {
        this[model] = this[model];
      }else if(unit.dataType === 1) {
        this[model] = this[model].replace(/[^\d]/g,'')
      }else if(unit.dataType === 2) {
        let str = this[model].replace(/[^\d^\.]+/g,'');
        if(str.split('.')[1] && str.split('.')[1].length > 2) {
          str = str.split('.')[0] + '.' + str.split('.')[1].substring(0, 2);
        }
        this[model] = str;
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>