<template>
  <el-dialog
    title="设置规格值"
    :visible.sync="dialogVisible"
    :before-close="clearModel"
    :append-to-body="true"
    width="400px"
    @open="openDialog"
    @closed="specValue = ''"
  >
    <!-- 单选 -->
    <div
      v-if="specData.valueType == 2"
      v-loading="loading"
    >
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          v-for="(item,i) in specList"
          :key="i"
          :label="item.id"
          style="margin-bottom: 10px"
        >
          {{ item.name }}
          <!-- <span>{{specData.tradeUnitDto ? specData.tradeUnitDto.unitName : ''}}</span> -->
          <i
            v-if="item.belongType === 2"
            class="el-icon-delete"
            @click.stop="deleteSkuVal(item)"
          ></i>
        </el-checkbox>
      </el-checkbox-group>

      <el-link
        type="primary"
        style="margin-top: 10px;"
        @click="showSelf = true"
      >
        +添加自定义规格值
      </el-link>
    </div>
    <!-- 输入 -->
    <div
      v-if="specData.valueType == 1"
      v-loading="loading"
    >
      <!-- <div style="margin-bottom: 10px;">
        已添加规格值：<span v-if="!value || value.length === 0">暂未添加</span> <span v-for="(item, index) in value" :key="index" style="margin-right: 30px;font-weight: bold;margin-bottom: 10px;display: inline-block;">{{item.name}}<i style="color: red;cursor: pointer" class="el-icon-delete" @click="clearInputSpec(item, index)"></i></span>
      </div> -->

      <InputModel
        :spec-value.sync="specValue"
        :unit-date="specData.tradeUnitDto"
      />
    </div>
    <!-- 时间 -->
    <div
      v-if="specData.valueType == 3"
      v-loading="loading"
    >
      请选择时间：
      <el-time-picker
        v-model="specValue"
        format="HH:mm"
        placeholder="选择时间"
        value-format="HH:mm"
        size="small"
      />
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="clearModel"
      >取 消</el-button>
      <el-button
        v-if="+specData.valueType === 2"
        :disabled="!specList || specList.length === 0"
        type="primary"
        size="small"
        @click="onSubmit"
      >保 存</el-button>
      <el-button
        v-if="+specData.valueType === 1 || +specData.valueType === 3"
        :disabled="!specValue"
        type="primary"
        size="small"
        @click="onSubmit"
      >保 存</el-button>
    </span>

    <el-dialog
      title="添加自定义规格值"
      :visible.sync="showSelf"
      width="400px"
      append-to-body
      @closed="specValue = ''"
    >
      <InputModel
        :spec-value.sync="specValue"
        :unit-date="specData.tradeUnitDto"
      />

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :disabled="!specValue"
          type="primary"
          size="mini"
          @click="onAddSelf"
        >保 存</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import InputModel from './inputModel';

export default {
  name: '',
  components: {
    InputModel,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      loading: false,
      specList: null,
      checkList: [],
      specData: {},
      specValue: '',

      showSelf: false, // 自定义规格值弹窗
    };
  },
  computed: {
  },
  watch: {
    // value: {
    //   handler (val) {
    //     this.checkList = val
    //   },
    //   deep: true
    // }
  },
  created() {},
  mounted() {

  },
  methods: {
    openDialog() {
      this.loading = true;
      this.$axios
        .get(this.$api.goods_spec.detail, {
          params: { specId: this.id },
        })
        .then((res) => {
          if (res.code === 0) {
            const specDto = {
              ...res.data,
              tradeBackendCategoryDtoList: undefined,
            };
            this.specData = specDto;
            this.specList = specDto.tradeSpecValueDtos;
            if (this.value) {
              this.checkList = this.value.map((item) => item.id);
            }
          }
          this.loading = false;
        });
      console.log(this.id, '选择id');
    },
    clearModel() {
      this.$emit('update:dialogVisible', false);
    },
    async onSubmit() {
      if (+this.specData.valueType === 1 || +this.specData.valueType === 3) {
        if (this.specValue) {
          let unitVal = this.specValue + ((this.specData.tradeUnitDto && this.specData.tradeUnitDto.unitName) ? this.specData.tradeUnitDto.unitName : '');
          let res = await this.addSkuValApi(unitVal);
          if (res.code === 0) {
            let arr = this.value;
            arr.push({
              id: res.data,
              name: unitVal,
            });
            this.$emit('update:value', arr);
            this.$message({
              type: 'success',
              message: '添加成功!',
            });
            this.specValue = '';
          }
        }
      } else if (+this.specData.valueType === 2) {
        this.$emit('update:value', this.specList.filter((item) => {
          return this.checkList.find((find) => +find === +item.id);
        }));
      }
      this.clearModel();
    },
    // 保存，自定义
    async onAddSelf() {
      let unitVal = this.specValue + ((this.specData.tradeUnitDto && this.specData.tradeUnitDto.unitName) ? this.specData.tradeUnitDto.unitName : '');
      let res = await this.addSkuValApi(unitVal);
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: '添加成功!',
        });
        this.showSelf = false;
        this.openDialog();
      }
    },
    // addSkuVal() {
    //   this.$prompt('请输入自定义规格值', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     inputValidator: (val)=> {
    //       if(val) {
    //         return true;
    //       }else {
    //         return false
    //       }
    //     },
    //     inputErrorMessage: '输入不能为空'
    //   }).then(async ({ value }) => {
    //     let res = await this.addSkuValApi(value);
    //     if (res.code === 0) {
    //         this.$message({
    //           type: 'success',
    //           message: '添加成功!'
    //         });
    //         this.openDialog();
    //       }
    //   }).catch(() => {
    //   });
    // },
    addSkuValApi(value) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.$api.goods_spec.add_spec_value, {
            specId: this.id,
            name: value,
          })
          .then((res) => {
            resolve(res);
          });
      });
    },
    // 删除输入规格值
    // async clearInputSpec(row, i) {
    //   // await this.deteleSkuValApi(row.id);
    //   let arr = this.value;
    //   arr.splice(i, 1);
    //   this.$emit('update:value', arr);
    //   // this.$message({
    //   //   type: 'success',
    //   //   message: '删除成功!',
    //   // });
    // },
    async deleteSkuVal(row) {
      let res = await this.deteleSkuValApi(row.id);
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
        this.openDialog();
      }
    },
    deteleSkuValApi(id) {
      return new Promise((resolue, rejecr) => {
        this.$confirm('是否确认删除规格值?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$axios
            .get(this.$api.goods_spec.del_spec_value, {
              params: { specValueId: id },
            })
            .then((res) => {
              resolue(res);
            });
        }).catch(() => {
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
